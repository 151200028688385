
import { Vue, Component, Ref } from "vue-property-decorator";
import ValidatedForm from "./ValidatedForm.vue";
import ValidatedFormGroup from "./ValidatedFormGroup.vue";
import { AxiosError } from "axios";

@Component({
  components: {
    ValidatedForm,
    ValidatedFormGroup,
  },
})
export default class SignupForm extends Vue {
  @Ref() private readonly signupForm!: ValidatedForm;
  private readonly username = "";
  private readonly password = "";
  private readonly passwordConfirm = "";
  private readonly termsAccepted = false;

  private async signup() {
    await this.$store.state.api.createPlayer(this.username, this.password);
    await this.$store.dispatch("login", {
      username: this.username,
      password: this.password,
    });
  }

  private signupError(err: AxiosError) {
    if (err.response && err.response.status == 409) {
      this.signupForm.setError("username", err.response.data.detail);
    }
  }
}
