
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Position, Trick, Partnership } from "@/api/types";
import SelfPositionMixin from "./selfposition";
import PartnershipMixin from "./partnership";
import _ from "lodash";

@Component
export default class TricksWonDisplay extends mixins(
  SelfPositionMixin,
  PartnershipMixin
) {
  @Prop({ default: () => [] }) private readonly tricks!: Array<Trick>;

  private get usText() {
    return this.selfPosition
      ? "Us"
      : this.partnershipText(Partnership.northSouth);
  }

  private get themText() {
    return this.selfPosition
      ? "Them"
      : this.partnershipText(Partnership.eastWest);
  }

  private get ourPositions() {
    return [this.playerPosition, this.partnerPosition];
  }

  private get theirPositions() {
    return [this.lhoPosition, this.rhoPosition];
  }

  private tricksWon(positions: Array<Position | undefined>) {
    return _.sumBy(this.tricks, (trick) =>
      Number(positions.includes(trick.winner))
    );
  }

  private get ourTricks() {
    return this.tricksWon(this.ourPositions);
  }

  private get theirTricks() {
    return this.tricksWon(this.theirPositions);
  }
}
