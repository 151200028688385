
import { Vue, Component } from "vue-property-decorator";
import SearchGamesForm from "./SearchGamesForm.vue";
import CreateGameForm from "./CreateGameForm.vue";

@Component({
  components: {
    SearchGamesForm,
    CreateGameForm,
  },
})
export default class GameSelector extends Vue {}
