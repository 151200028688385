
import { Vue, Component, Prop } from "vue-property-decorator";
import CallDisplay from "./CallDisplay.vue";
import { Call } from "@/api/types";

@Component({
  components: {
    CallDisplay,
  },
})
export default class CallPanel extends Vue {
  @Prop({ default: () => [] }) private readonly allowedCalls!: Array<Call>;

  callKey(call: Call) {
    let ret: Array<string> = [call.type];
    if (call.bid) {
      ret = ret.concat([String(call.bid.level), call.bid.strain]);
    }
    return ret.join("-");
  }
}
