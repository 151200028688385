
import { Component, Vue } from "vue-property-decorator";
import { GameSummary, PlayersInGame, Position, Player } from "@/api/types";
import PositionDisplay from "./PositionDisplay.vue";
import _ from "lodash";

@Component({
  components: {
    PositionDisplay,
  },
})
export default class SearchGamesForm extends Vue {
  private q = "";
  private games: Array<GameSummary> = [];

  private get hasGames() {
    return this.q && !_.isEmpty(this.games);
  }

  private playerList(players: PlayersInGame) {
    //  typescript doesn't understand that filter removes non-null
    //  players, so perform a cast to suppress warning
    return _.values(Position)
      .filter((position) => players[position] !== null)
      .map((position) => ({
        position,
        username: (players[position] as Player).username,
      }));
  }

  private selectGame(gameId: string) {
    this.$emit("game-selected", gameId);
  }

  private _searchGames() {
    if (this.q) {
      this.$store.state.api
        .searchGames(this.q)
        .then((games: Array<GameSummary>) => (this.games = games))
        .catch((err: Error) => this.$store.dispatch("reportError", err));
    } else {
      this.games = [];
    }
  }

  private searchGames = _.debounce(this._searchGames, 100);
}
