
import Component from "vue-class-component";
import Vue from "vue";
import LoginForm from "./LoginForm.vue";
import SignupForm from "./SignupForm.vue";

@Component({
  components: {
    LoginForm,
    SignupForm,
  },
})
export default class Login extends Vue {}
