
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import RankDisplayMixin from "./rankdisplaymixin";
import SuitDisplayMixin from "./suitdisplaymixin";

@Component
export default class CardDisplay extends mixins(
  RankDisplayMixin,
  SuitDisplayMixin
) {
  @Prop({ default: () => false }) private readonly allowed!: boolean;

  private get cardClasses() {
    const classes = [`rank-${this.rank}`, `suit-${this.suit}`];
    if (this.allowed) {
      classes.push("allowed");
    }
    return classes;
  }

  private playCard() {
    if (this.allowed) {
      const card = { rank: this.rank, suit: this.suit };
      this.$emit("play", card);
    }
  }
}
