
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import _ from "lodash";
import PositionDisplay from "./PositionDisplay.vue";
import CallDisplay from "./CallDisplay.vue";
import CallPanel from "./CallPanel.vue";
import { Position, Call, PositionCallPair } from "@/api/types";
import SelfPositionMixin from "./selfposition";

@Component({
  components: {
    PositionDisplay,
    CallDisplay,
    CallPanel,
  },
})
export default class Bidding extends mixins(SelfPositionMixin) {
  @Prop({ default: () => [] }) private readonly calls!: Array<PositionCallPair>;
  @Prop() private readonly positionInTurn?: Position;
  @Prop({ default: false }) private readonly northSouthVulnerable!: boolean;
  @Prop({ default: false }) private readonly eastWestVulnerable!: boolean;
  @Prop({ default: () => [] }) private readonly allowedCalls!: Array<Call>;

  private get positions() {
    return [
      this.lhoPosition,
      this.partnerPosition,
      this.rhoPosition,
      this.playerPosition,
    ];
  }

  private positionClasses(position: Position) {
    return {
      vulnerable: [Position.north, Position.south].includes(position)
        ? this.northSouthVulnerable
        : this.eastWestVulnerable,
    };
  }

  private callClasses(call: PositionCallPair) {
    return this.callPositionClasses(call.position);
  }

  private get displayCallPanel() {
    return (
      !_.isEmpty(this.allowedCalls) &&
      this.positionInTurn == this.playerPosition
    );
  }

  private callPositionClasses(position: Position) {
    return `position-${position}`;
  }
}
