
import { Vue, Component, Ref } from "vue-property-decorator";
import ValidatedForm from "./ValidatedForm.vue";
import ValidatedFormGroup from "./ValidatedFormGroup.vue";
import { GameCreate } from "../api/types";

@Component({
  components: {
    ValidatedForm,
    ValidatedFormGroup,
  },
})
export default class CreateGameForm extends Vue {
  @Ref() private readonly createGameForm!: ValidatedForm;
  private gameCreate = new GameCreate();

  async createGame() {
    const api = this.$store.state.api;
    const game = await api.createGame(this.gameCreate);
    await api.joinGame(game.id);
    this.$emit("game-selected", game.id);
    this.gameCreate = new GameCreate();
    this.createGameForm.reset();
  }
}
