
import { Vue, Component, Ref } from "vue-property-decorator";
import ValidatedForm from "./ValidatedForm.vue";
import ValidatedFormGroup from "./ValidatedFormGroup.vue";

@Component({
  components: {
    ValidatedForm,
    ValidatedFormGroup,
  },
})
export default class LoginForm extends Vue {
  @Ref() private readonly loginForm!: ValidatedForm;
  private readonly username = "";
  private readonly password = "";

  async login() {
    await this.$store.dispatch("login", {
      username: this.username,
      password: this.password,
    });
    if (!this.$store.state.isLoggedIn) {
      this.loginForm.setError("username", "Incorrect username or password");
    }
  }
}
