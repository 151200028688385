
import { Vue, Component, Prop } from "vue-property-decorator";
import { Bid } from "@/api/types";
import SuitDisplay from "./SuitDisplay.vue";

@Component({
  components: {
    SuitDisplay,
  },
})
export default class Bidding extends Vue {
  @Prop() private readonly bid!: Bid;

  private get bidClasses() {
    return [`level-${this.bid.level}`, `strain-${this.bid.strain}`];
  }
}
