
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import SeatLabel from "./SeatLabel.vue";
import HandDisplay from "./HandDisplay.vue";
import TrickDisplay from "./TrickDisplay.vue";
import { PlayersInGame, Position, Cards, Trick, Card } from "@/api/types";
import SelfPositionMixin from "./selfposition";
import { partnerFor } from "@/utils";

@Component({
  components: {
    SeatLabel,
    HandDisplay,
    TrickDisplay,
  },
})
export default class TableDisplay extends mixins(SelfPositionMixin) {
  @Prop({ default: () => new PlayersInGame() })
  private readonly players!: PlayersInGame;
  @Prop({ default: () => new Cards() }) private readonly cards!: Cards;
  @Prop({ default: () => [] }) allowedCards!: Array<Card>;
  @Prop() private readonly trick?: Trick;
  @Prop() private readonly positionInTurn?: Position;
  @Prop() private readonly declarer?: Position;

  private cardsFor(position: Position) {
    return this.cards[position];
  }

  private handClasses(position: Position) {
    const classes = [position as string];
    if (position == this.positionInTurn) {
      classes.push("turn");
    }
    if (position == this.declarer) {
      classes.push("declarer");
    } else if (partnerFor(position) == this.declarer) {
      classes.push("dummy");
    }
    return classes;
  }
}
