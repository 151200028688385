
import { Vue, Component, Watch, Ref } from "vue-property-decorator";
import { Player } from "@/api/types";
import ValidatedForm from "@/components/ValidatedForm.vue";
import ValidatedFormGroup from "@/components/ValidatedFormGroup.vue";
import { AxiosError } from "axios";

@Component({
  components: {
    ValidatedForm,
    ValidatedFormGroup,
  },
})
export default class Account extends Vue {
  @Ref() private readonly changePasswordForm!: ValidatedForm;
  private player: Player | null = null;
  private currentPassword = "";
  private newPassword = "";
  private passwordConfirm = "";
  private passwordChanged = false;

  @Watch("$store.getters.isLoggedIn", { immediate: true })
  async onLogin(isLoggedIn: boolean) {
    if (isLoggedIn) {
      this.player = await this.$store.state.api.getMe();
    } else {
      this.player = null;
    }
  }

  async changePassword() {
    await this.$store.state.api.changePassword(
      this.currentPassword,
      this.newPassword
    );
    this.currentPassword = "";
    this.newPassword = "";
    this.passwordConfirm = "";
    this.changePasswordForm.reset();
    this.passwordChanged = true;
  }

  changePasswordError(err: AxiosError) {
    if (err.response && err.response.status == 401) {
      this.changePasswordForm.setError("currentPassword", "Incorrect password");
    }
  }
}
