
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import CardDisplay from "./CardDisplay.vue";
import { Trick } from "@/api/types";
import SelfPositionMixin from "./selfposition";
import _ from "lodash";

@Component({
  components: {
    CardDisplay,
  },
})
export default class TrickDisplay extends mixins(SelfPositionMixin) {
  @Prop({ default: () => ({ cards: [] }) }) private readonly trick!: Trick;

  private get cards() {
    if (this.trick.cards) {
      return _.fromPairs(this.trick.cards.map((pc) => [pc.position, pc.card]));
    }
    return {};
  }
}
